import { render, staticRenderFns } from "./Rounding.vue?vue&type=template&id=995b8716&"
import script from "./Rounding.vue?vue&type=script&lang=js&"
export * from "./Rounding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports